<template>
  <el-skeleton
    v-if="loading || !expectedRewardBullets.length"
    loading
    animated
    :count="5"
    class="bullet-list-skeleton"
    effect="dark"
  >
    <template #template>
      <el-skeleton-item variant="rect" />
    </template>
  </el-skeleton>
  <el-table
    v-else
    :data="data"
    :row-key="(row: BulletTableRow) => row.address"
    class="bullet-list-table"
    header-row-class-name="bullet-list-table__header"
  >
    <el-table-column>
      <template #header>
        <ui-text variant="body4" color="textSecondary">BULLET</ui-text>
      </template>
      <template #default="{ row }">
        <BulletCell :data="row" />
      </template>
    </el-table-column>
    <el-table-column v-if="showTotal" align="right">
      <template #header>
        <ui-text variant="body4" color="textSecondary">Generated</ui-text>
      </template>
      <template #default="{ row }">
        <InfoCell
          :data="{
            totalUSD: row.totalUSD,
            total: row.total,
          }"
        />
      </template>
    </el-table-column>
    <el-table-column align="right">
      <template #header>
        <ui-text variant="body4" color="textSecondary">My sharing</ui-text>
      </template>
      <template #default="{ row }">
        <InfoCell
          :data="{
            totalUSD: row.myShareUSD,
            total: row.myShare,
          }"
        />
      </template>
    </el-table-column>
  </el-table>
</template>
<script setup lang="ts">
import { Big } from 'big.js'
import dayjs from 'dayjs'
import BulletCell from './BulletCell.vue'
import InfoCell from './InfoCell.vue'
import type { BulletTableRow } from '@base/types/bullet'
import { OptionType } from '@base/types/options'

const props = defineProps({
  rows: {
    type: Array as PropType<BulletTableRow[]>,
    default: () => [],
  },
  showTotal: {
    type: Boolean,
    default: false,
  },
})

const { showTotal, rows } = toRefs(props)

const { expectedRewardBullets, loading } = useBulletReward()

const { divDecimals, getOriginalSymbol } = useTokens()

const expectedBulletListData = computed(() => {
  return expectedRewardBullets.value
    .map((item) => {
      const optionType =
        String(item.optionType) === OptionType.SELL ? 'SELL' : 'BUY'
      const token =
        String(item.optionType) === OptionType.SELL
          ? 'sellTokenBULLET'
          : 'uBULLET'
      const myShareRaw =
        divDecimals(item.myBullet || '0', 'bullet').value || '0'
      const totalRaw =
        divDecimals(item.totalBalance || '0', 'bullet').value || '0'

      return {
        address: item.id,
        token: getOriginalSymbol(token),
        optionType,
        strike: formatStrikePrice(item.strikePrice || 0),
        expiry: dayjs(Number(item.exerciseTimestamp + '000')).format('D MMM'),
        totalRaw,
        total: useTokenNumberFormat(totalRaw, { token: 'BULLET' }),
        totalUSD: useUSDFormat(Big(item.bulletPrice).times(totalRaw).toFixed()),
        myShareRaw,
        myShare: useTokenNumberFormat(myShareRaw, { token: 'BULLET' }),
        myShareUSD: useUSDFormat(
          Big(item.bulletPrice).times(myShareRaw).toFixed(),
        ),
      }
    })
    .sort((a, b) => {
      const myShareDiff = Big(b.myShareRaw).sub(Big(a.myShareRaw)).toNumber()
      if (myShareDiff !== 0) {
        return myShareDiff
      } else {
        return Big(b.totalRaw).sub(Big(a.totalRaw)).toNumber()
      }
    })
})
const data = computed(() => {
  if (rows.value.length > 0) {
    return rows.value
  }
  return expectedBulletListData.value
})
</script>
<style lang="postcss" scoped>
.bullet-list-table.el-table {
  --el-table-border-color: transparent;
  --el-table-row-hover-bg-color: transparent;
  --el-table-current-row-bg-color: transparent;
  --el-table-header-bg-color: transparent;
  --el-table-fixed-box-shadow: none;
  --el-table-bg-color: transparent;
  --el-table-tr-bg-color: transparent;
  --el-table-expanded-cell-bg-color: transparent;
  --el-table-fixed-left-column: inset 10px 0 10px -10px rgba(0, 0, 0, 0.15);
  --el-table-fixed-right-column: inset -10px 0 10px -10px rgba(0, 0, 0, 0.15);
  --el-table-index: var(--el-index-normal);
  &:deep(.bullet-list-table__header) {
    padding: 4px 10px;
    & .el-table__cell {
      padding: 4px 0 8px;
      border-bottom: 1px solid var(--brand-color-text-tertiary);
      & .cell {
        line-height: 24px;
      }
    }
  }
  &:deep(.el-table__body-wrapper) {
    margin-top: 4px;
  }
  &:deep(.el-table__row) {
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:deep(.el-table__cell) {
    padding: 10px 0;
    border-bottom-width: 0;
    & .cell {
      max-height: 48px;
      padding: 0;
    }
    &:first-child {
      padding-left: 10px;
    }
    &:last-child {
      padding-right: 10px;
    }
  }
}
.bullet-list-skeleton {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  --el-skeleton-color: var(--brand-color-secondary-hover);
  --el-skeleton-to-color: var(--brand-color-secondary);

  & .el-skeleton__item {
    width: 100%;
    height: 68px;
    &:first-child {
      height: 37px;
    }
  }
}
</style>
